let links = [];

for (let i = 0; i < 19; i++) {
  links.push("/images/new-gallery/" + i + ".jpg");
}

const photos = [
  { src: "/images/new-gallery/0.jpg", height: 576, width: 1024 },
  { src: "/images/new-gallery/8.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/9.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/14.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/15.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/17.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/16.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/12.jpg", height: 576, width: 1024 },
  { src: "/images/new-gallery/13.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/11.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/10.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/18.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/4.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/5.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/7.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/6.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/2.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/3.jpg", height: 1024, width: 768 },
  { src: "/images/new-gallery/1.jpg", height: 1024, width: 768 },
];
// const photos = [
//   { src: "/images/gallery/8.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/9.jpg", height: 1200, width: 1600 },
//   { src: "/images/gallery/14.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/28.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/29.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/15.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/17.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/16.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/12.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/13.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/11.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/10.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/21.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/35.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/34.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/20.jpg", height: 1200, width: 1600 },
//   { src: "/images/gallery/36.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/22.jpg", height: 1200, width: 1600 },
//   { src: "/images/gallery/23.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/33.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/27.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/26.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/32.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/18.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/24.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/30.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/31.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/25.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/19.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/4.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/5.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/7.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/6.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/2.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/3.jpg", height: 1600, width: 1200 },
//   { src: "/images/gallery/1.jpg", height: 1200, width: 1600 },
//   { src: "/images/gallery/0.jpg", height: 1600, width: 1200 },
// ];
export default photos;
