import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { dataportfolio, meta } from "../../content_option";

export const Portfolio = () => {
  const items = dataportfolio.map((data, i) => {
    return (
      <div key={i} className="po_item">
        <div className="imageContainer">
          <img src={data.img} alt="" />
        </div>
        <div className="content">
          <h3>{data.title}</h3>
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "semibold"
            }}
          >
            {data.description}
          </p>
          {data.additionalNote ? (
            <p
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: "gray",
                maxWidth: "80%"
              }}
            >
              {data.additionalNote}
            </p>
          ) : null}
          <a href={data.link} target="_blank" rel="noopener noreferrer">
            <div id="button_p" className="ac_btn btn">
              Data Sheet
              <div className="ring one"></div>
              <div className="ring two"></div>
              <div className="ring three"></div>
            </div>
          </a>
        </div>
      </div>
    );
  });
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Equipment | {meta.title} </title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Equipment </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <div className="items">{items}</div>
      </Container>
    </HelmetProvider>
  );
};
