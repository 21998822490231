import "./style.css";
import { Link } from "react-router-dom";
import { contactConfig } from "../content_option";
const Footermain = () => {
  return (
    <>
    <hr></hr>
      <div className="footer">
        <div className="footer__container flex flex-row flex-wrap">
          <div className="footer__links flex flex-column">
            <Link to="/" className="footer__link">
              Home
            </Link>
            <Link to="/equipment" className="footer__link">
              Equipment
            </Link>
            <Link to="/process" className="footer__link">
              Process
            </Link>
            <Link to="/contact" className="footer__link">
              Contact
            </Link>
            <Link to="/gallery" className="footer__link">
              Gallery
            </Link>
          </div>
          <div className="footer__social flex flex-column">
            {/* phone number */}
            <a
              href={`tel:${contactConfig.YOUR_FONE.replace("-", "").replace("(","").replace(")", "").replace(" ","")}`}
              className="footer__link"
            >
              {contactConfig.YOUR_FONE}
            </a>
            <a
              href={`tel:${contactConfig.YOUR_CELL.replace("-", "").replace("(","").replace(")", "").replace(" ","")}`}
              className="footer__link"
            >
              {contactConfig.YOUR_CELL}
            </a>
            {/* email */}
            <a
              className="footer__link"
              href={`mailto:${contactConfig.YOUR_EMAIL}`}
            >
              {contactConfig.YOUR_EMAIL}
            </a>
            {/* address */}
            <a
              className="footer__link"
              href="https://goo.gl/maps/16kr8aDBstT7Zfsn6"
              target="_blank"
              rel="noreferrer"
            >
              40 Holtby Avenue, Brampton, ON
            </a>
          </div>
          <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.4201628040837!2d-79.79118682356575!3d43.681027671100836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b38b17dd82cbf%3A0x13c3aafb1ade0938!2sL%20K%20Magicut!5e0!3m2!1sen!2sca!4v1681528869168!5m2!1sen!2sca" width="300" height="200" style={{border:0, paddingBottom:"10px"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="br-top"></div>
      <div className="br-bottom"></div>
      <div className="br-left"></div>
      <div className="br-right"></div>
    </>
  );
};

export default Footermain;
