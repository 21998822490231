const logotext = "LK Magicut";
const meta = {
  title: "LK Magicut",
  description: "We are a CNC machine company based in Brampton Ontario"
};

const maxCapacity = <b>236″ x 95″ x 47″</b>;

const introdata = {
  title: "Welcome to LK Magicut",
  animated: {
    first: "Large capacity CNC machining"
    // second: "Efficient CNC machining capabilities",
    // third: "From file to finished part"
  },
  description: (
    <p>
      Unleash the power of large-scale CNC machining with us. Our capabilities
      reach new heights with a maximum capacity of {maxCapacity}. We excel in
      tackling ambitious projects that others simply can't handle. From massive
      fabrication to intricate designs, our expertise and cutting-edge equipment
      ensure precision and quality. Experience unmatched results for your
      large-scale machining needs.`
    </p>
  ),
  your_img_url: ""
};

const dataabout = {
  title: "a bit about my self",
  aboutme:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis dolor id ligula semper elementum feugiat pretium nulla. Nunc non commodo dolor. Nunc mollis dignissim facilisis. Morbi ut magna ultricies."
};

const dataportfolio = [
  {
    title: "FPT FLOOR TYPE BORING MILL",
    description: "236″ x 95″ x 47″ travel",
    link: "/resources/FPT SIRIO M40 FRESATRICI Controllo USED Master Group Srl.pdf",
    img: "/images/machines/8.jpg"
  },
  {
    img: "/images/machines/1.jpg",
    description: "157.5″ x 82″ x 39″ travel and 4000RPM",
    link: "https://www.awea.com/awea_en/milling/production-bridge/lp/specifications_21.htm",
    title: "AWEA 4021-BRIDGE"
  },
  {
    img: "/images/machines/2.jpg",
    description: "86″ x 42″ x 35″ travel and 6000RPM",
    link: "https://www.takumiusa.com/wp-content/uploads/2021/09/Takumi-Data-Sheets_V22.pdf",
    title: "TAKUMAI VMC V22A"
  },
  {
    img: "/images/machines/3.jpg",
    description: "62″ x 32″ x 25″ travel and 6000RPM",
    link: "https://www.alfametalmachinery.com/en/select_by_brand/awea/CNC%20Vertical%20Machining%20Center/BM-BT50%20Series/549/1600/",
    title: "VMC AWEA 1600"
  },
  {
    img: "/images/machines/4.jpg",
    description: "72″ x 55″ x 36″ travel and 8000RPM; Horizontal Boring Mill",
    link: "https://www.fryermachine.com/pdf/HR%20Series%20Specifications.pdf",
    title: "HR 70 FRYER-HMC"
  },
  {
    img: "/images/machines/5.jpg",
    description: "125″ x 82″ x 31″ travel and 15000RPM",
    additionalNote: "Rotary Table 31″ x 31″ with 1 degree indexing",
    link: "https://www.takumicnc.eu/machining-center/highly-rigid-portal-machining-centers/h-32-t/#",
    title: "TAKUMI H32 T-BRIDGE"
  },
  {
    title: "OKK 520-VMC",
    description: "41″ x 21″ x 25″ travel and 6000RPM",
    link: "https://www.ezsystems.com/p/Okk-MCV-520-Vertical-Machining-Centers/542",
    img: "/images/machines/6.jpg"
  },
  {
    title: "TOSHIBA BORING MILL R16",
    description: "78″ x 59″ x 39″ B-AXIS 19″ travel and 4000RPM",
    additionalNote: "Rotary Table 60″ x 60″",
    link: "https://shibaura-machine.com/media/y0rarpgx/btd110h-r16-shibaura-leaflet1-1.pdf",
    img: "/images/machines/7.jpg"
  }
];

const contactConfig = {
  YOUR_EMAIL: "LK.magicut@gmail.com",
  YOUR_CELL: "(416) 816-6582",
  YOUR_FONE: "(905) 866-6582",
  description:
    "Thank you for considering our services. We are always eager to hear from potential clients and answer any questions you may have about our capabilities. We look forward to hearing from you!",
  // create an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id"
};

const socialprofils = {
  ig: "https://www.instagram.com/l.k.magicut/"
};

const materials = {
  title: "Gallery",
  description: (
    <p>
      Take a look at some of our noteworthy projects and workshop below, and
      take a look at our <a href={socialprofils.ig}>Instagram</a> for more!
    </p>
  ),
  items: []
};

const services = [
  {
    title: "Custom Machining",
    description:
      "Our experienced machinists and advanced equipment allow us to offer customized machining solutions to meet your specific requirements. Whether it's a complex shape, tight tolerance, or unique material, we have the expertise and resources to get the job done.",
    img: "/images/gallery/8.jpg"
  },
  {
    title: "Mould Component Making",
    description:
      "We specialize in creating high-quality, custom molds for a variety of applications. Our team works with you to develop the most effective mold design and fabrication process, ensuring a perfect fit for your needs. With our state-of-the-art equipment and attention to detail, you can trust us to deliver top-notch results.",
    img: "images/gallery/10.jpg"
  },
  {
    title: "General Weldments",
    description:
      "Our skilled welders are experienced in a wide range of welding techniques and materials. From MIG and TIG welding to brazing and soldering, we can handle any general welding project. We ensure high-quality and long-lasting welds for your industrial or commercial applications.",
    img: "/images/gallery/18.jpg"
  }
];

export {
  meta,
  dataabout,
  dataportfolio,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
  materials,
  maxCapacity
};
