import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";

export const About = () => {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Our Process | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Our Process</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <div className="tile drawing2part">
          <div className="tile_content">
            <h2>
              <strong>Our Process</strong>
            </h2>
            <p>
              At our manufacturing facility, we take pride in our ability to
              turn technical drawings into finished parts using state-of-the-art
              CNC machinery. With our precision CNC equipment, we can accurately
              and efficiently machine complex parts to meet even the most
              exacting specifications. Whether you need parts for automotive,
              medical, or other industries, we have the expertise and equipment
              to deliver high-quality finished products.
              <br></br>
              <br></br>
              Contact us today to learn more about how we can bring your
              technical drawings to life.
            </p>
          </div>
          <section className="drawing_to_part">
            <div className="drawing flex flex__center flex-column">
              <img
                src="/images/home/technical-drawing.jpg"
                alt="technical drawing"
                className="drawing_img"
              ></img>
              <p>
                <strong>Technical Drawing</strong>
                <br></br>
                We can work from your technical drawings to produce the parts
                you need.
              </p>
            </div>
            <div className="cad flex flex__center flex-column">
              <img
                src="/images/home/fusion360.jpg"
                alt="cad sketch"
                className="drawing_img"
              ></img>
              <p>
                <strong>CAD Sketch</strong>
                <br></br>
                We can also work from CAD sketches to produce the parts you
                need.
              </p>
            </div>
          </section>
        </div>
      </Container>
    </HelmetProvider>
  );
};
