import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import Typewriter from "typewriter-effect";
import { introdata, meta, services, maxCapacity } from "../../content_option";
import { Link } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

export const Home = () => {
  const services_render = services.map((service, index) => {
    return (
      <div key={index} className="tile service_box">
        <div className="service_icon">
          <img src={service.img} alt="" />
        </div>
        <div className="service_content">
          <h2>
            <strong>{service.title}</strong>
          </h2>
          {service.description}
        </div>
      </div>
    );
  });

  const slideShowLinks = [
    // intro photo
    "/images/gallery/8.jpg",
    // new gallery photos
    "/images/new-gallery/0.jpg",
    "/images/new-gallery/8.jpg",
    "/images/new-gallery/9.jpg",
    "/images/new-gallery/14.jpg",
    "/images/new-gallery/15.jpg",
    "/images/new-gallery/17.jpg",
    "/images/new-gallery/16.jpg",
    "/images/new-gallery/12.jpg",
    "/images/new-gallery/13.jpg",
    "/images/new-gallery/11.jpg",
    "/images/new-gallery/10.jpg",
    "/images/new-gallery/18.jpg",
    "/images/new-gallery/4.jpg",
    "/images/new-gallery/5.jpg",
    "/images/new-gallery/7.jpg",
    "/images/new-gallery/6.jpg",
    "/images/new-gallery/2.jpg",
    "/images/new-gallery/3.jpg",
    "/images/new-gallery/1.jpg",
  ];

  const slideShow = slideShowLinks.map((link, index) => {
    return (
      <div
        key={index}
        className="h_bg-image h-100 "
        style={{
          backgroundImage: `url(${link})`,
          width: "100%",
          objectFit: "scale-down",
        }}
      ></div>
    );
  });

  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center ">
          <div
            className="h_bg-image order-lg-2 h-100 "
            // style={{ backgroundImage: `url(${introdata.your_img_url})` }}
          >
            <Slide>{slideShow}</Slide>
          </div>
          <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
            <div className="align-self-center ">
              <div className="intro mx-auto">
                <h2 className="mb-1x">{introdata.title}</h2>
                <h1 className="fluidz-48 mb-1x">
                  {/* <Typewriter
                    options={{
                      strings: [
                        introdata.animated.first,
                        introdata.animated.second,
                        introdata.animated.third,
                      ],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: 10,
                    }}
                  /> */}
                  {introdata.animated.first}
                </h1>
                <p className="mb-1x">{introdata.description}</p>
                <div className="intro_btn-action pb-5">
                  <Link to="/contact">
                    <div id="button_h" className="ac_btn btn">
                      Contact Us
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                  <Link to="/gallery" className="text_2">
                    <div id="button_p" className="ac_btn btn ">
                      Gallery
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="logos">
          <h2>Trusted By</h2>
          <div className="logo_container">
            <div className="logo">
              <svg
                fill="#ffffff"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 320"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <title>brand-icon-tesla-solid1</title>{" "}
                  <g id="tesla">
                    <g id="T">
                      <path d="M160,318l44.4-249.7c42.3,0,55.7,4.7,57.6,23.6c0,0,28.4-10.6,42.7-32.1c-55.9-25.9-112-27.1-112-27.1l-32.8,39.9h0.1 l-32.8-39.9c0,0-56.1,1.2-112,27.1c14.3,21.5,42.7,32.1,42.7,32.1c2-18.9,15.3-23.6,57.3-23.6L160,318z"></path>{" "}
                      <path d="M160,21.2c45.2-0.4,96.8,7,149.8,30.1c7.1-12.7,8.9-18.4,8.9-18.4C268.2,12.8,214.4,2.3,160,2 C105.6,2.3,51.8,12.8,1.3,32.9c2.6,6.3,5.5,12.4,8.9,18.4C63.1,28.2,114.8,20.9,160,21.2L160,21.2"></path>{" "}
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="logo">
              <svg
                fill="#ffffff"
                viewBox="0 0 24 24"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M12 8.236C5.872 8.236.905 9.93.905 12.002S5.872 15.767 12 15.767c6.127 0 11.094-1.693 11.094-3.765 0-2.073-4.967-3.766-11.094-3.766zm-5.698 6.24c-.656.005-1.233-.4-1.3-1.101a1.415 1.415 0 0 1 .294-1.02c.195-.254.525-.465.804-.517.09-.017.213
                  -.006.264.054.079.093.056.194-.023.234-.213.109-.47.295-.597.55a.675.675 0 0 0 .034.696c.263.397.997.408 1.679-.225.169-.156.32-.304.473-.48.3-.344.4-.47.8-1.024.005-.006.006-.014.004-.018-.003-.007-.009-.01-.02-.01-.267.007-.5.087-.725.255-.065.048-.
                  159.041-.2-.021-.046-.07-.013-.163.062-.215.363-.253.76-.298 1.166-.367 0 0 .028.002.051-.03.167-.213.292-.405.47-.621.178-.22.41-.42.586-.572.246-.212.404-.283.564-.37.043-.022-.005-.049-.018-.049-.896-.168-1.827-.386-2.717-.056-.616.23-.887.718-.757
                  1.045.093.231.397.27.683.13a1.55 1.55 0 0 0 .611-.544c.087-.134.27-.038.171.195-.26.611-.757 1.097-1.363 1.118-.516.016-.849-.363-.848-.831.002-.924 1.03-1.532 2.11-1.622 1.301-.108 2.533.239 3.825.395.989.12 1.938.123 2.932-.106.118-.025.2.05.193.168-
                  .01.172-.143.337-.47.516-.373.204-.763.266-1.17.27-.984.008-1.901-.376-2.85-.582.002.041.012.091-.023.117-.525.388-1 .782-1.318 1.334-.011.013-.005.025.013.024.277-.015.525-.022.783-.042.045-.004.047-.015.043-.048a.64.64 0 0 1 .2-.558c.172-.153.387-.17.53-.0
                  6.16.126.147.353.058.523a.63.63 0 0 1-.382.31s-.03.006-.026.034c.006.043.2.151.217.18.017.027.008.07-.021.102a.123.123 0 0 1-.095.045c-.033 0-.053-.012-.096-.035a.92.92 0 0 1-.27-.217c-.024-.031-.037-.032-.099-.029-.279.017-.714.059-1.009.096-.071.008-.082.022-.09
                  6.047-.47.775-.972 1.61-1.523 2.17-.592.6-1.083.758-1.604.762zM19.05 10.71c-.091.158-1.849 2.834-1.96 3.11-.035.088-.04.155-.004.204.092.124.297.051.425-.038.381-.262.645-.58.937-.858.017-.013.046-.018.065 0 .043.04.106.091.15.137a.04.04 0 0 1 .002.057 5.873 5.
                  873 0 0 1-.904.911c-.47.364-.939.457-1.172.224a.508.508 0 0 1-.14-.316c-.002-.057-.031-.06-.058-.034-.278.275-.76.579-1.198.362-.366-.18-.451-.618-.383-.986.001-.008-.006-.06-.051-.03a1.28 1.28 0 0 1-.3.162.853.853 0 0 1-.366.077.518.518 0 0 1-.451-.253.759.759
                   0 0 1-.095-.347c-.001-.011-.017-.032-.033-.005-.3.457-.579.899-.875 1.363-.016.022-.03.036-.06.037l-.587.001c-.036 0-.053-.028-.034-.063.104-.2.674-1.03 1.06-1.736.107-.194.085-.294.019-.337-.083-.054-.248.027-.387.133-.379.287-.697.735-.859.935-.095.117-.185.291-
                   .433.56-.391.425-.91.669-1.408.5a.848.848 0 0 1-.546-.58c-.015-.052-.044-.066-.073-.032-.08.1-.245.249-.383.342-.015.011-.052.033-.084.017a.851.851 0 0 1-.152-.199.07.07 0 0 1 .016-.08c.197-.173.305-.271.391-.38.064-.08.113-.17.17-.315.12-.302.393-.866.938-1.158a1
                   81 1.81 0 0 1 .652-.219c.1-.01.183.002.213.08.011.033.039.105.056.158.011.032.003.057-.035.071-.32.122-.643.311-.865.61-.253.338-.321.746-.152.98.123.17.322.2.514.139.29-.092.538-.363.666-.663.138-.329.16-.717.058-1.059-.016-.059-.001-.104.037-.136.077-.063.184-.
                   112.215-.128a.14.14 0 0 1 .182.045c.106.157.163.378.17.607.006.049.026.05.05.025.19-.202.366-.418.568-.58.185-.147.422-.267.643-.262.286.006.428.2.419.546-.001.044.03.04.051.011a1.19 1.19 0 0 1 .24-.264c.198-.163.4-.236.611-.222.26.02.468.257.425.527a.53.53 0 0 1-.
                   281.406.362.362 0 0 1-.405-.044.336.336 0 0 1-.096-.322c.005-.025-.027-.048-.054-.02-.254.264-.273.606-.107.76.183.17.458.056.658-.075.366-.239.65-.563.979-.813.218-.166.467-.314.746-.351a.87.87 0 0 1 .454.052c.2.081.326.25.342.396.004.043.036.048.063.01.158-.246
                    1.005-1.517 1.075-1.65.02-.041.044-.047.089-.047h.606c.035 0 .051.02.036.047zm-2.32 2.204a.053.053 0 0 0-.003.04c.003.02.03.04.056.05.01.003.015.01.004.032-.075.16-.143.252-.237.391a1.472 1.472 0 0 1-.3.325c-.178.147-.424.307-.628.2-.09-.047-.13-.174-.127-.276.0
                    04-.288.132-.584.369-.875.288-.355.607-.539.816-.438.216.103.148.354.05.55zm-5.949-1.881a.398.398 0 0 1 .132-.345c.057-.05.133-.062.18-.022.052.045.027.157-.026.234a.43.43 0 0 1-.245.177c-.018.004-.034-.004-.041-.044zM12 7.5C5.34 7.5 0 9.497 0 12c0 2.488 5.383 4.
                    5 12 4.5s12-2.02 12-4.5-5.383-4.5-12-4.5zm0 8.608C5.649 16.108.5 14.27.5 12.002.5 9.733 5.65 7.895 12 7.895s11.498 1.838 11.498 4.107c0 2.268-5.148 4.106-11.498 4.106z"></path>
                </g>
              </svg>
            </div>
            <div className="logo">
              <svg
                viewBox="-0.66 0 47.787 47.787"
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M42.55 1.139C40.542.694 31.395.01 23.338 0 15.155.01 5.921.694 3.923 1.139c-.97.215-3.926.872-3.923 4.303-.003 4.414.112 26.593 2.379 29.691 3.927 5.379 19.819 12.288 20.503 12.58.215.097.47.098.708 0 .689-.289 17.194-7.212 20.528-12.631 2.171-3.528 2.346-29.383 2.354-29.641-.006-3.43-2.962-4.087-3.922-4.302zM23.591 46.044c0 .007 0 .007 0 0 0 .007 0 .007 0 0 0 .007 0 .007 0 0z"></path>
                  <path
                    d="M42.576 34.146c-2.78 4.509-16.914 10.711-19.339 11.745-2.374-1.049-16.06-7.256-19.389-11.821-1.487-2.027-2.04-18.636-2.051-28.628.011-1.443.691-2.13 2.531-2.531 1.704-.387 10.699-1.091 19.01-1.089 8.181-.002 17.093.702 18.808 1.089 1.83.401 2.51 1.088 2.506 2.531.004 5.153-.539 26.199-2.076 28.704z"
                    fill="#7f7f7f"
                  ></path>
                  <path d="M23.971 12.605c.247-.853 2.114-2.084 2.405-1.822-.011-.315 1.347-1.559 1.848-1.342-.121-.377 1.741-1.542 2.177-1.215.108-.473 2.806-.83 3.341-.38.308-.345 2.701.235 2.759.836.441-.385 2.882 2.195 2.886 3.493 0 .507-2.296-1.54-3.746-1.089-2.39.728-5.438 3.276-8.075 7.113-.737 1.043-2.624 1.424-3.038-.278 0 .001-.814-4.458-.557-5.316z"></path>
                  <path d="M23.769 36.399c-.384-.637-.265-1.4.05-1.696.446-.429 1.723-.083 1.823-.481.252-.911-.396-1.233-.278-2.506.032-.441.873-3.661 1.088-3.949.178-.236 1.253-.611 1.544-.733.177-.201.302-.444.304-.861-1.396-.438-1.773.869-1.974.811-.202-.042.103-1.423.278-1.569.176-.155 1.824-.521 2-.811.205-.307.965-3.245 0-3.29.086.012-.04-.199.025-.178 1.984.458 3.319-8.993 8.176-8.96 4.834.043 4.582 7.036 4.303 7.29-.554.491-1.354.312-1.392.759-.24 4.277-4.062 8.854-5.214 8.455-.696-.244-.104-2.417.228-2.456-.307-.138-.005-2.375.354-2.43-.284-.197-.033-2.259.329-2.278-.181-.162-.162-1.574-.531-2.278-.271-.538-1.468-.828-1.544.481.733-.347.783 2.018.481 2.43-.301.393-2.237 1.298-2.835 1.797.044 1.009-.911 4.881-1.62 6-.699 1.095-1.403 1.749-1.797 2.759-.411 1.001-.153 2.716-1.063 3.493-.407.34-2.57.492-2.735.201z"></path>
                  <path d="M40.348 20.554c.234-.385 1.162-.538 1.494-.354.396.222.687 3.297.278 3.392.358.184-.088 2.517-.532 2.531.352.14-.377 2.428-.81 2.354.256.351-.925 2.211-1.24 2.102-.112 1.92-5.875 2.833-5.746 1.215.063-.776.925.367 4.81-5.67 1.704-2.68 1.416-4.993 1.746-5.57zM22.528 12.631c-.398-.888-2.05-2.069-2.329-1.797 0-.325-1.358-1.569-1.848-1.342.11-.388-1.753-1.553-2.202-1.215-.094-.483-2.792-.84-3.316-.38-.319-.355-2.712.225-2.759.835-.452-.395-2.893 2.185-2.886 3.494-.011.497 2.285-1.551 3.721-1.114 2.404.742 5.452 3.291 8.1 7.113.726 1.059 2.572 1.439 2.987-.279.001 0 .939-4.443.532-5.315z"></path>
                  <path d="M22.73 36.425c.373-.672.305-1.411-.025-1.721-.431-.414-1.683-.043-1.797-.43-.238-.922.411-1.244.304-2.506-.043-.452-.884-3.671-1.088-3.949-.19-.247-1.264-.622-1.544-.734-.188-.211-.312-.455-.329-.886 1.411-.424 1.788.884 2 .836.19-.053-.114-1.434-.278-1.595-.188-.141-1.811-.531-2.025-.785-.221-.296-.951-3.255 0-3.315-.072.027.054-.185-.025-.178-1.97.473-3.305-8.979-8.176-8.935-4.82.032-4.568 7.025-4.278 7.29.542.481 1.343.302 1.367.759.253 4.267 4.076 8.844 5.24 8.429.685-.229.093-2.401-.228-2.455.296-.122-.005-2.36-.354-2.404.273-.208.021-2.27-.33-2.304.169-.147.151-1.56.506-2.253.284-.549 1.482-.839 1.544.481-.718-.357-.769 2.007-.455 2.404.29.407 2.226 1.312 2.834 1.822-.055.999.9 4.871 1.595 5.974.713 1.11 1.417 1.765 1.822 2.76.403 1.025.591 3.032 1.088 3.493.446.406 2.466.513 2.632.202zM23.288 38.374c-1.514.028-2.838-1.047-2.582-1.342.529-.548 1.432.264 2.506-.202 1.064.441 1.944-.344 2.632.177.395.284-1.053 1.346-2.556 1.367z"></path>
                  <path d="M6.202 20.604c-.22-.396-1.147-.447-1.494-.253-.382.21-.673 3.185-.278 3.29-.344.174.102 2.507.557 2.507-.362.154.367 2.442.785 2.379-.242.34.939 2.201 1.266 2.101.102 1.911 5.864 2.823 5.746 1.216-.074-.787-.936.356-4.809-5.695-1.717-2.665-1.428-4.977-1.773-5.545z"></path>
                </g>
              </svg>
            </div>
          </div>
        </div> */}
        <hr></hr>
        <div className="tile_container">
          <div className="tile">
            <div>
              <h2>
                <strong>Specialization</strong>
              </h2>
            </div>
            <div className="tile_content" style={{ marginBottom: "2%" }}>
              <p>
                At L.K. Magicut, we specialize in large-scale machining, and we
                have some of the largest and most advanced machines in the
                industry. Our state-of-the-art equipment and experienced
                machinists allow us to tackle even the most challenging
                machining projects with precision and efficiency.
              </p>
              <p>
                One area of our expertise is large plate machining, which is a
                difficult operation that requires specialized equipment and
                expertise. With our advanced machinery and experienced team, we
                can handle large plate machining projects with ease, delivering
                high-quality results that meet your exact specifications.
              </p>
              <p>
                Whether you need large-scale production runs or customized
                one-off projects, we have the resources and expertise to handle
                your large-scale machining needs. Contact us today to learn more
                about our capabilities and how we can help you achieve your
                machining goals.
              </p>
              <Link to="/equipment" className="text_2">
                <div id="button_p" className="ac_btn btn">
                  Our Machines
                  <div className="ring one"></div>
                  <div className="ring two"></div>
                  <div className="ring three"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="tile_container">
          <div className="tile tool_and_die">
            <div className="tile_content">
              <h2>
                <strong>Machining and Fabrication</strong>
              </h2>
              <div className="tile_content_container flex flex-center">
                <p className="article_item">
                  <strong>Large Capacity</strong>
                  <br></br>
                  <br></br>
                  We have a range of large CNC machines with expansive work
                  areas, making us the perfect choice for sizable machining
                  projects. Our machines are capable of tackling even the most
                  challenging jobs, with a maximum machining area of{" "}
                  {maxCapacity} (inches).
                </p>
                <p>
                  <strong>Precision Manufacturing</strong>
                  <br></br>
                  <br></br>
                  We use advanced technology to produce parts with exceptional
                  precision and accuracy. Our team of experienced technicians
                  oversees the entire manufacturing process to ensure that every
                  part meets the required specifications.
                </p>
                <p>
                  <strong>Fast Turnaround</strong>
                  <br></br>
                  <br></br>
                  Our shop operates 7 days a week, including holidays, to
                  provide you with quick turnaround times. Whether you need a
                  single part or a large production run, we are ready to help.
                </p>
              </div>
            </div>
            <p>
              Whether you need a small batch of parts or large-scale production
              runs, our team can handle it all. We have the expertise and
              equipment to deliver high-quality finished products.
            </p>
            {/* <Link to="/contact">
              <div id="button_h" className="ac_btn btn">
                Contact Us
                <div className="ring one"></div>
                <div className="ring two"></div>
                <div className="ring three"></div>
              </div>
            </Link> */}
          </div>

          <div className="tile">
            <div className="tile_content">
              <h2>
                <strong>Precision Machining</strong>
              </h2>
            </div>
            <p>
              We specialize in manufacturing CNC machined parts through milling
              processes, and our machine capabilities cover prototyping,
              small-batch production, as well as large-scale production orders.
              As a CNC machine shop, we have extensive experience working with a
              diverse range of materials, such as aluminum, steel, stainless
              steel, plastics, and many more.
            </p>
          </div>

          <div className="tile">
            <div className="tile_content rapid">
              <h2>
                <strong>Rapid CNC Machining</strong>
              </h2>
              <div id="article-1" className="flex flex__row">
                <div className="flex flex__column">
                  <p className="article_item">
                    To address urgent orders, we have the capacity to furnish
                    our clients with prompt CNC machined components. Our CNC
                    machine shop is located in-house and employs its full range
                    of machining abilities to ensure successful outcomes on
                    every occasion.
                  </p>

                  <p className="article_item">
                    At L.K. Magicut, we offer CNC machining services for
                    customer-supplied materials, as well as forgings and
                    castings. Our services include deburring, and mirror
                    finishing on various materials such as aluminum and
                    stainless steel.
                  </p>

                  <p className="article_item">
                    Furthermore, we possess the ability to create
                    stress-relieved CNC precision parts in-house to fulfill
                    customer requirements. We provide shorter lead times and
                    end-to-end manufacturing solutions in the Peel region.
                  </p>
                </div>
                <div className="img_cont">
                  <img
                    src="/images/drill.jpg"
                    alt="drill"
                    id="article-1-img"
                    style={{ maxHeight: "300px", objectFit: "contain" }}
                  />
                </div>
              </div>
            </div>
          </div>
          {services_render}
          <div className="tile milling">
            {/* <div className="tile_content">
              <h2>Milling</h2>
              <p>
                Our milling services utilize state-of-the-art equipment to
                deliver precise and efficient results for a variety of
                materials.
              </p>
              <div className="image_display">
                <div className="image_block">
                  <h4>Steel</h4>
                  <img src="/images/home/steel-block.png" alt="steel block" />
                </div>
                <div className="image_block">
                  <h4>Plastic</h4>
                  <img
                    src="/images/home/plastic-block.png"
                    alt="plastic block"
                  />
                </div>
                <div className="image_block">
                  <h4>Aluminium</h4>
                  <img
                    src="/images/home/aluminium-block.png"
                    alt="aluminium block"
                  />
                </div>
                <div className="image_block">
                  <h4>Brass</h4>
                  <img src="/images/home/brass-block.png" alt="brass block" />
                </div>
                <div className="image_block">
                  <h4>Copper</h4>
                  <img src="/images/home/copper-block.png" alt="copper block" />
                </div>
              </div>
              <br></br>
              <p>
                From prototypes to large-scale production runs, we offer custom
                milling solutions tailored to meet your specific needs and
                requirements. Our team of experienced technicians and engineers
                ensure that every project is executed with the utmost precision
                and quality.
              </p>
                  </div> */}
          </div>
        </div>
        <div className="services">
          <div className="services_container"></div>
        </div>
      </section>
    </HelmetProvider>
  );
};
