import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta, materials } from "../../content_option";
import { Row, Col, Container } from "react-bootstrap";
import { useState } from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import photos from "./photos";

const slides = photos;

export const Gallery = () => {
  const [index, setIndex] = useState(-1);
  return (
    <HelmetProvider>
      <Container className="About-header page-enter-done">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Gallery | {meta.title} </title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">{materials.title}</h1>
            <hr className="t_border my-4 ml-0 text-left" />
            <p className="mat_desc">{materials.description}</p>
          </Col>
        </Row>
        <PhotoAlbum
          photos={photos}
          layout="rows"
          targetRowHeight={300}
          onClick={({ index }) => setIndex(index)}
        />

        <Lightbox
          slides={slides}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          // enable optional lightbox plugins
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
      </Container>
    </HelmetProvider>
  );
};
